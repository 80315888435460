import imageOne from '../assets/images/news/1.jpg';
import imageTwo from '../assets/images/news/2.jpg';
import imageTree from '../assets/images/news/3.jpg';
import imageFour from '../assets/images/news/4.jpg';
import imageFive from '../assets/images/news/5.jpg';
import imageSix from '../assets/images/news/6.jpg';

const imageMap = {
  1: imageOne,
  2: imageTwo,
  3: imageTree,
  4: imageFour,
  5: imageFive,
  6: imageSix,
};

export default imageMap;
